import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.leadStepperState || initialState;

export const selectFormData = createSelector(
  [selectDomain],
  leadStepperState => leadStepperState.formData,
);

export const selectCompiledTemplate = createSelector(
  [selectDomain],
  leadStepperState => leadStepperState.compiledTemplate,
);

export const selectSendEmail = createSelector(
  [selectDomain],
  leadStepperState => leadStepperState.sendEmail,
);

export const selectVehicleInfo = createSelector(
  [selectDomain],
  leadStepperState => leadStepperState.vehicleInfo,
);
