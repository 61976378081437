import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { leadStepperActions } from 'app/pages/HomePage/slice';

export function* getCompiledTemplate({ payload }: ReturnType<any>) {
  try {
    const requestURL = `/templates/compileTemplate`;
    const response = yield call(request, requestURL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        // Authorization: header(),
      }),
      body: JSON.stringify(payload.data),
    });

    if (response.statusCode === 500) {
      yield put(leadStepperActions.requestCompiledTemplateError(response));
    }
    yield put(leadStepperActions.requestCompiledTemplateSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(leadStepperActions.requestCompiledTemplateError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(leadStepperActions.requestCompiledTemplateError(err.response));
    } else {
      yield put(leadStepperActions.requestCompiledTemplateError(err));
    }
  }
}

export function* sendEmail({ payload }: ReturnType<any>) {
  try {
    const requestURL = `/templates/emailTemplate`;
    const response = yield call(request, requestURL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        // Authorization: header(),
      }),
      body: JSON.stringify(payload.data),
    });

    if (response.statusCode === 500) {
      yield put(leadStepperActions.requestSendEmailError(response));
    }
    yield put(leadStepperActions.requestSendEmailSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(leadStepperActions.requestSendEmailError(err.response));
    } else if (err.message === 'Server Error') {
      yield put(leadStepperActions.requestSendEmailError(err.response));
    } else {
      yield put(leadStepperActions.requestSendEmailError(err));
    }
  }
}

export function* getVehicleInfo({ payload }: ReturnType<any>) {
  try {
    const requestURL = `/recommend/generate_copy`;
    const response = yield call(request, requestURL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        // Authorization: header(),
      }),
      body: JSON.stringify(payload.data),
    });
    console.log({ response });
    if (response.statusCode === 500) {
      yield put(leadStepperActions.requestVehicleInfoError(response));
    }
    yield put(leadStepperActions.requestVehicleInfoSuccess(response));
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(leadStepperActions.requestVehicleInfoError(err.response));
    }
    if (err.response?.status === 429) {
      yield put(
        leadStepperActions.requestVehicleInfoError({
          message: 'The AI Engine has too many requests;',
        }),
      );
    } else if (err.message === 'Server Error') {
      yield put(leadStepperActions.requestVehicleInfoError(err.response));
    } else {
      yield put(leadStepperActions.requestVehicleInfoError(err));
    }
  }
}

export function* leadStepperSaga() {
  yield takeLatest(
    leadStepperActions.requestCompiledTemplate.type,
    getCompiledTemplate,
  );
  yield takeLatest(leadStepperActions.requestSendEmail.type, sendEmail);
  yield takeLatest(leadStepperActions.requestVehicleInfo.type, getVehicleInfo);
}
