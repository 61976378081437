import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';

// The initial state of the dormantCampaigns container
export const initialState: ContainerState = {
  formData: {
    data: {
      lead_first_name: '',
      lead_last_name: '',
      interested_vehicle_name: '',
      lead_preference: '',
      email: '',
    },
    loading: false,
    error: null,
  },
  compiledTemplate: {
    data: {},
    loading: false,
    error: null,
  },
  sendEmail: {
    data: {},
    loading: false,
    error: null,
  },
  vehicleInfo: {
    data: {},
    loading: false,
    error: null,
  },
};

const leadStepperSlice = createSlice({
  name: 'leadStepperState',
  initialState,
  reducers: {
    editFormData(state, action: PayloadAction<any>) {
      state.formData.data = action.payload;
    },
    requestCompiledTemplate(state, action: PayloadAction<any>) {
      state.compiledTemplate.data = {};
      state.compiledTemplate.loading = true;
      state.compiledTemplate.error = null;
    },
    requestCompiledTemplateSuccess(state, action: PayloadAction<any>) {
      state.compiledTemplate.data = action.payload;
      state.compiledTemplate.loading = false;
    },
    requestCompiledTemplateError(state, action: PayloadAction<any>) {
      state.compiledTemplate.error = action.payload;
      state.compiledTemplate.loading = false;
    },
    requestSendEmail(state, action: PayloadAction<any>) {
      state.sendEmail.data = {};
      state.sendEmail.loading = true;
      state.sendEmail.error = null;
    },
    requestSendEmailSuccess(state, action: PayloadAction<any>) {
      state.sendEmail.data = action.payload;
      state.sendEmail.loading = false;
    },
    requestSendEmailError(state, action: PayloadAction<any>) {
      state.sendEmail.error = action.payload;
      state.sendEmail.loading = false;
    },
    clearSendEmail(state) {
      state.sendEmail.data = {};
    },
    requestVehicleInfo(state, action: PayloadAction<any>) {
      state.vehicleInfo.data = {};
      state.vehicleInfo.loading = true;
      state.vehicleInfo.error = null;
    },
    requestVehicleInfoSuccess(state, action: PayloadAction<any>) {
      state.vehicleInfo.data = action.payload;
      state.vehicleInfo.loading = false;
    },
    requestVehicleInfoError(state, action: PayloadAction<any>) {
      // console.log(action);
      state.vehicleInfo.error = action.payload;
      state.vehicleInfo.loading = false;
      console.log('vehicleInfo', state.vehicleInfo.error?.message);
    },
  },
});

export const {
  actions: leadStepperActions,
  reducer,
  name: sliceKey,
} = leadStepperSlice;
