import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { TypeAnimation } from 'react-type-animation';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Unstable_Grid2';
import lastPage from 'app/images/last_page.png';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { leadStepperSaga } from 'app/pages/HomePage/saga';
import {
  leadStepperActions,
  reducer,
  sliceKey,
} from 'app/pages/HomePage/slice';
import {
  selectCompiledTemplate,
  selectSendEmail,
  selectVehicleInfo,
  selectFormData,
} from 'app/pages/HomePage/selectors';

import Logo from 'app/images/logo.png';
import { template } from 'app/components/template';
import { randomQuotes, randomPrice, validateEmail } from 'utils/randomQuotes';
import { dealer_intro_auto_block_280 } from 'app/components/dummyText';
// import DefaultImage from 'app/images/Default_myautoIQ_Demo.png';

export function TemplateStepsPage({}) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: leadStepperSaga });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const compiledTemplateDetails = useSelector(selectCompiledTemplate);
  const compiledTemplate = compiledTemplateDetails.data.body || '';
  const vehicleInfoDetails = useSelector(selectVehicleInfo);

  const vehicleInfo = vehicleInfoDetails.data.body || '';
  const sentEmailDetails = useSelector(selectSendEmail);
  const sentEmail = sentEmailDetails.data.body || '';
  const formDataDetails = useSelector(selectFormData);
  const formData = formDataDetails.data;

  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);

  const [email, setEmail] = React.useState('');
  const [quote, setQuote] = React.useState(randomQuotes);
  const [interested_vehicle_auto_block1, setInterested_vehicle_auto_block1] =
    React.useState('');
  const [interested_vehicle_auto_block2, setInterested_vehicle_auto_block2] =
    React.useState('');
  const [secondPara, setSecondPara] = React.useState(false);
  const [showNextButton, setShowNextButton] = React.useState(false);
  const [multiPara, setMultiPara] = React.useState({});
  // const [error, setError] = React.useState<any>('');
  // if (vehicleInfoDetails.error) setError(vehicleInfoDetails.error.message);
  const [dealerForm, setDealerForm] = React.useState({
    dealerName: '',
    dealerFirstName: '',
    dealerLastName: '',
    dealerEmail: '',
  });

  React.useEffect(() => {
    let data = {};
    for (let i = 0; i < dealer_intro_auto_block_280.length; i++) {
      data[`para${i + 1}`] = false;
    }

    // @ts-ignore
    data.para1 = true;
    setMultiPara(data);

    getQuotes();
  }, []);

  React.useEffect(() => {
    handleActiveStep();
  }, [activeStep, vehicleInfoDetails.loading]);

  React.useEffect(() => {
    handleCompiledTemplate();
  }, [compiledTemplate]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleActiveStep = () => {
    if (activeStep === 0 && !vehicleInfoDetails.loading) {
      setActiveStep(1);

      let para: any = [];
      if (vehicleInfo.interested_vehicle_auto_block) {
        let stringArray = vehicleInfo.interested_vehicle_auto_block.split('. ');

        console.log('array: ', stringArray);
        for (let i = 0; i < stringArray.length; i++) {
          para.push(stringArray[i]);

          if (i === 1) {
            let data = para.join('. ');
            setInterested_vehicle_auto_block1(data + '.');
            para = [];
          } else if (i === 3) {
            let data = para.join('. ');
            setInterested_vehicle_auto_block2(data + '.');
            break;
          }
        }
      }
    }

    if (activeStep === 2) {
      let price = `$${randomPrice().toLocaleString('en-US')}`;
      let template_data = {
        ...formData,
        ...vehicleInfo,
        interested_vehicle_image: process.env.REACT_APP_DEFAULT_TEMPLATE_IMAGE,
        interested_vehicle_msrp: price,
        interested_vehicle_price: price,
        interested_vehicle_auto_block1,
        interested_vehicle_auto_block2,
      };

      dispatch(
        leadStepperActions.requestCompiledTemplate({
          data: {
            template,
            template_data,
          },
        }),
      );
    }
  };

  const sendEmail = () => {
    dispatch(
      leadStepperActions.requestSendEmail({
        data: { leadForm: formData, body: compiledTemplate },
      }),
    );
  };

  // const validateForm = () => {
  //   let errorList: any = {};
  //   Object.keys(dealerForm).map(item => {
  //     console.log('item', item);
  //     switch (item) {
  //       case 'dealerName':
  //         if (!dealerForm.dealerName.trim().length) {
  //           errorList.dealerName = true;
  //         }
  //         break;

  //       case 'dealerFirstName':
  //         if (!dealerForm.dealerFirstName.trim().length) {
  //           errorList.dealerFirstName = true;
  //         }
  //         break;

  //       case 'dealerLastName':
  //         if (!dealerForm.dealerLastName.trim().length) {
  //           errorList.dealerLastName = true;
  //         }
  //         break;

  //       case 'dealerEmail':
  //         let emailValidation = validateEmail(dealerForm.dealerEmail);
  //         if (!dealerForm.dealerEmail.trim().length || !emailValidation) {
  //           errorList.dealerEmail = true;
  //         }
  //         break;

  //       default:
  //         break;
  //     }
  //   });

  //   setError(errorList);

  //   if (Object.keys(errorList).length) {
  //     return false;
  //   }

  //   console.log(errorList);
  //   return true;
  // };

  const getQuotes = () => {
    if (activeStep === 0) {
      setInterval(() => {
        let data: any = randomQuotes();
        setQuote(data);
      }, 5000);
    }
  };

  const handleCompiledTemplate = () => {
    if (compiledTemplate.length) {
      sendEmail();
    }
  };

  return (
    <>
      {/* {} */}
      <Helmet>
        <title>Lead Response</title>
        <meta name="description" content="AI Lead Response" />
      </Helmet>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        // justifyContent="center"
        style={{ height: 'fit-content', marginTop: '3%' }}
      >
        {/* <Grid
          xs={12}
          md={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={Logo}
            style={{
              width: '150px',
            }}
          />
        </Grid> */}

        <Grid
          xs={10}
          md={6}
          // display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {vehicleInfoDetails.error?.message && (
            <p>
              {vehicleInfoDetails.error?.message}{' '}
              <a href="/">Please try again</a>
            </p>
          )}
          {activeStep === 0 ? (
            <div style={{ width: '100%' }}>
              <Typography
                variant="h1"
                gutterBottom
                style={{ textAlign: 'center' }}
              >
                myautoIQ AI magic at work...
              </Typography>

              <Typography
                variant="h3"
                gutterBottom
                style={{
                  textAlign: 'center',
                  marginBottom: 50,
                  fontSize: '22px',
                }}
              >
                Crafting lead responses... It may take up to 60 seconds...
              </Typography>

              <Typography
                variant="h3"
                gutterBottom
                style={{
                  textAlign: 'center',
                  fontSize: '22px',
                  fontFamily: ['Sen', 'sans-serif'].join(','),
                }}
              >{`"${quote.text || ''}" - ${quote.autor || ''}`}</Typography>
              <LinearProgress sx={{ marginTop: 10 }} />
            </div>
          ) : null}

          {activeStep === 1 && interested_vehicle_auto_block1.length > 1 ? (
            <div style={{ width: '100%' }}>
              <Typography variant="h1" gutterBottom textAlign={'center'}>
                Piecing compelling information about the vehicle of interest
              </Typography>

              {interested_vehicle_auto_block1.length > 0 ? (
                <>
                  <TypeAnimation
                    sequence={[
                      interested_vehicle_auto_block1,
                      1000,
                      () => {
                        console.log('1st para');
                        setSecondPara(true);
                      },
                    ]}
                    wrapper="div"
                    cursor={false}
                    speed={60}
                    style={{
                      marginTop: '60px',
                      textAlign: 'center',
                      fontFamily: ['Sen', 'sans-serif'].join(','),
                      fontSize: '22px',
                      color: '#545454',
                    }}
                  />
                  {secondPara ? (
                    <TypeAnimation
                      sequence={[
                        interested_vehicle_auto_block2,
                        1000,
                        () => {
                          console.log('2nd para');
                          setShowNextButton(true);
                        },
                      ]}
                      wrapper="div"
                      cursor={true}
                      speed={60}
                      style={{
                        marginTop: '60px',
                        textAlign: 'center',
                        fontFamily: ['Sen', 'sans-serif'].join(','),
                        fontSize: '22px',
                        color: '#545454',
                      }}
                    />
                  ) : null}
                </>
              ) : null}
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{
                  display: showNextButton ? 'flex' : 'none',
                  margin: '40px auto',
                  width: '120px',
                  fontFamily: ['Sen', 'sans-serif'].join(','),
                  fontSize: '22px',
                }}
              >
                Next
              </Button>
            </div>
          ) : null}

          {activeStep === 2 ? (
            <div style={{ width: '100%' }}>
              <Typography
                variant="h1"
                gutterBottom
                style={{ textAlign: 'center' }}
              >
                Check your email inbox for your AI generated response
              </Typography>

              <Typography
                variant="h3"
                gutterBottom
                style={{
                  textAlign: 'center',
                  marginBottom: 50,
                  fontSize: '22px',
                  fontFamily: ['Sen', 'sans-serif'].join(','),
                }}
              >
                You'll receive the AI generated snippet about the shopper's
                vehicle of interest, their individual interest, shortly. Feel
                free to use it for your follow-up email to engage and delight
                the shopper!
              </Typography>

              <Link
                href="/"
                sx={{
                  fontFamily: ['Montserrat', 'sans-serif'].join(','),
                  fontSize: '22px',
                  color: '#737373',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Generate another response
              </Link>

              <Typography
                variant="h1"
                gutterBottom
                style={{ textAlign: 'center', marginTop: '80px' }}
              >
                5 Key elements of dealership sales follow-up emails that hold
                shopper's interest
              </Typography>

              <Typography
                variant="h3"
                gutterBottom
                style={{
                  textAlign: 'center',
                  marginBottom: 50,
                  fontSize: '22px',
                  fontFamily: ['Sen', 'sans-serif'].join(','),
                }}
              >
                Personalized vehicle information is one part of a good sales
                follow-up email strategy. Wondering what else you need?{' '}
              </Typography>

              <Link
                href="https://www.myautoiq.com/5-key-elements-of-dealer-follow-up-emails"
                sx={{
                  fontFamily: ['Montserrat', 'sans-serif'].join(','),
                  fontSize: '22px',
                  color: '#737373',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Read our guide.
              </Link>
            </div>
          ) : null}

          {/* not required */}
          {/* {activeStep === 2 ? (
            <div style={{ width: '100%' }}>
              <Typography
                variant="h1"
                gutterBottom
                style={{ textAlign: 'center' }}
              >
                Generating relevant, stand out dealer value content
              </Typography>

              {dealer_intro_auto_block_280.split('. ').map((item, index) => {
                if (multiPara[`para${index + 1}`]) {
                  // if para1 is true, give component
                  return (
                    <TypeAnimation
                      sequence={[
                        index <
                        dealer_intro_auto_block_280.split('. ').length - 1
                          ? item + '.'
                          : item,
                        1000,
                        () => {
                          console.log(`${index + 1} para`);
                          setMultiPara({
                            ...multiPara,
                            [`para${index + 2}`]: true,
                          });
                        },
                      ]}
                      wrapper="div"
                      cursor={false}
                      speed={60}
                      style={{
                        marginTop: '20px',
                        textAlign: 'left',
                        fontFamily: ['Sen', 'sans-serif'].join(','),
                        fontSize: '22px',
                      }}
                    />
                  );
                }
              })}
            </div>
          ) : null} */}

          {/* {activeStep === 3 ? (
            <div style={{ width: '100%' }}>
              <Typography
                variant="h1"
                gutterBottom
                style={{ textAlign: 'center' }}
              >
                Getting inventory details, images, and call to actions from the
                dealer
              </Typography>

              <div
                style={{
                  width: '60%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Skeleton animation="wave" variant="rectangular" height={60} />

                <Skeleton
                  animation="wave"
                  style={{ margin: '20px auto' }}
                  variant="text"
                  width="50%"
                  height={50}
                />

                <Skeleton
                  animation="wave"
                  variant="text"
                  width="20%"
                  height={30}
                />

                <Skeleton
                  animation="wave"
                  variant="text"
                  width="60%"
                  height={30}
                />

                <Skeleton animation="wave" variant="text" height={100} />

                <Skeleton
                  animation="wave"
                  variant="text"
                  height={150}
                  // style={{ margin: '10px' }}
                />
              </div>
            </div>
          ) : null} */}

          {/* {activeStep === 4 ? (
            <Grid xs={12} md={12}>
              <Typography
                variant="h1"
                gutterBottom
                style={{ textAlign: 'center' }}
              >
                Voila! High-fidelity relevant, follow-ups message to engage the
                prospect!
              </Typography>

              <div
                style={{
                  maxHeight: '500px',
                  minHeight: 'auto',
                  overflow: 'auto',
                  marginTop: 5,
                }}
                dangerouslySetInnerHTML={{
                  __html: compiledTemplate,
                }}
              ></div>
            </Grid>
          ) : null} */}

          {/* {activeStep === 5 ? (
            <>
              <Grid xs={12} md={12}>
                <Typography
                  variant="h1"
                  gutterBottom
                  style={{ textAlign: 'center' }}
                >
                  Email me the generated template
                </Typography>
              </Grid>
              <Grid
                xs={12}
                md={6}
                justifyContent="center"
                alignItems="center"
                sx={{ margin: 'auto' }}
              >
                <Stack spacing={2}>
                  <TextField
                    id="outlined-basic"
                    label="Dealer Name"
                    variant="outlined"
                    value={dealerForm.dealerName}
                    error={error.dealerName || false}
                    helperText={error.dealerName ? 'Name is required*' : ''}
                    onChange={e =>
                      setDealerForm({
                        ...dealerForm,
                        dealerName: e.target.value,
                      })
                    }
                    fullWidth
                  />

                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    value={dealerForm.dealerFirstName}
                    error={error.dealerFirstName || false}
                    helperText={
                      error.dealerFirstName ? 'First name is required*' : ''
                    }
                    onChange={e =>
                      setDealerForm({
                        ...dealerForm,
                        dealerFirstName: e.target.value,
                      })
                    }
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    value={dealerForm.dealerLastName}
                    error={error.dealerLastName || false}
                    helperText={
                      error.dealerLastName ? 'Last name is required*' : ''
                    }
                    onChange={e =>
                      setDealerForm({
                        ...dealerForm,
                        dealerLastName: e.target.value,
                      })
                    }
                    fullWidth
                  />
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={dealerForm.dealerEmail}
                    error={error.dealerEmail || false}
                    helperText={error.dealerEmail ? 'Email is required*' : ''}
                    onChange={e =>
                      setDealerForm({
                        ...dealerForm,
                        dealerEmail: e.target.value,
                      })
                    }
                    fullWidth
                  />

                  <Button
                    sx={{ borderRadius: 25, marginTop: 20 }}
                    variant="contained"
                    onClick={() => handleClose()}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </>
          ) : null} */}

          {/* {activeStep === 2 ? (
            <div style={{ width: '100%' }}>
              <h1 style={{ textAlign: 'center' }}>
                Putting together specific tit bits about the vehicle of interest
              </h1>
              <TypeAnimation
                sequence={[vehicleInfo.interested_vehicle_features_block, 1000]}
                wrapper="div"
                cursor={true}
                speed={60}
                style={{ marginTop: '60px', textAlign: 'left' }}
              />
            </div>
          ) : null} */}

          {/* not required */}
          {/* {activeStep === 7 ? (
            <div style={{ width: '100%' }}>
              <h1 style={{ textAlign: 'center' }}>
                Lead response for vehicle for lead at dealership dealer
              </h1>

              <Grid container direction="row">
                <Grid xs={12} md={4}>
                  <ul>
                    <span style={{ fontSize: '1.3em' }}>
                      Generate unique response for each lead
                    </span>
                    <li style={{ marginTop: 30, fontSize: '1.1em' }}>
                      Engage the lead with relevant information
                    </li>
                    <li style={{ marginTop: 5, fontSize: '1.1em' }}>
                      Highlight dealer offers
                    </li>
                    <li style={{ marginTop: 5, fontSize: '1.1em' }}>
                      Provide reasons to buy from dealership
                    </li>
                  </ul>
                </Grid>
                <Grid xs={12} md={8}>
                  <div
                    style={{
                      maxHeight: '500px',
                      minHeight: 'auto',
                      overflow: 'auto',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: compiledTemplate,
                    }}
                  ></div>
                </Grid>
                <Grid xs={12} md={8}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    onChange={e => setEmail(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid
                  xs={12}
                  md={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    sx={{ borderRadius: 25 }}
                    variant="contained"
                    onClick={() => sendEmail()}
                    disabled={!email}
                    // fullWidth
                  >
                    Send this to me
                  </Button>
                </Grid>
              </Grid>

              <Alert
                severity="success"
                sx={{
                  marginTop: 2,
                  display:
                    sentEmail === 'email sent successfully' ? '' : 'none',
                }}
              >
                MyautoIq AI Lead Response emailed to you!
              </Alert>
            </div>
          ) : null} */}

          {/* not required */}
          {/* {activeStep === 8 ? (
            <Grid xs={12} md={12}>
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  marginTop: 5,
                }}
                src={lastPage}
              />
            </Grid>
          ) : null} */}
        </Grid>

        {/* <Grid xs={10} md={6}>
          {activeStep > 0 && activeStep !== 3 && activeStep !== 5 ? (
            <MobileStepper
              sx={{ bgcolor: 'black' }}
              variant="progress"
              steps={6}
              position="static"
              activeStep={activeStep}
              nextButton={
                activeStep === 8 ? (
                  <Button size="small" onClick={handleClose}>
                    Finish
                  </Button>
                ) : (
                  <Button
                    size="small"
                    onClick={handleNext}
                    // @ts-ignore
                    disabled={activeStep === 8}
                  >
                    Next
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                )
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          ) : null}
        </Grid> */}
      </Grid>
    </>
  );
}
