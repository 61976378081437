const quotes = [
  {
    text: 'Fast cars are my only vice.Michael BayA racing car is an animal with a thousand adjustments.',
    autor: 'Mario Andretti',
  },
  {
    text: 'The cars we drive say a lot about us.',
    autor: 'Alexandra Paul',
  },
  {
    text: 'Cars are the reason we, you know, people live or die.',
    autor: 'Steven Curtis Chapman',
  },
  {
    text: 'If you’re in control, you’re not going fast enough.',
    autor: 'Parnelli Jones',
  },
  {
    text: 'Good cars get you from point A to point B. Great cars. Well they just get you in to trouble.',
    autor: 'Unknown',
  },
  {
    text: 'It’s more fun to drive a slow car fast, than to drive a fast car slow.',
    autor: 'Unknown',
  },
  {
    text: 'Have you ever noticed that anybody driving slower than you is an idiot, and anyone going faster than youis a maniac?',
    autor: 'George Carlin',
  },
  {
    text: 'Leave sooner, drive slower, and live longer.',
    autor: 'Unknown',
  },
  {
    text: 'I am emotional about engines, if you hurt my car, you hurt my heart.',
    autor: 'Amit Kalantri',
  },
  {
    text: 'Race cars are neither beautiful nor ugly. They become beautiful when they win.',
    autor: 'Enzo Ferrari',
  },
  {
    text: 'Straight roads are for fast cars, turns are for fast drivers.',
    autor: 'Colin McRae',
  },
  {
    text: 'When a man opens a car door for his wife, it’s either a new car or a new wife.',
    autor: 'Prince Philip',
  },
  {
    text: 'I thought cars were essential ingredients of life itself.',
    autor: 'Edward Herrmann',
  },
  {
    text: 'I’ve always been into cars. Cars are part of our genetic makeup. It’s unavoidable.',
    autor: 'Matthew Fox',
  },
  {
    text: 'Race cars, no matter what size or shape they are, they do the same things. It is not complicated.',
    autor: 'Tony Stewart',
  },
  {
    text: 'You can know or not know how a car runs and still enjoy riding in a car.',
    autor: 'David Byrne',
  },
  {
    text: 'Cars bring me sheer joy.',
    autor: 'Evan Spiegel',
  },
  {
    text: 'Electric cars aren’t pollution-free; they have to get their energy from somewhere.',
    autor: 'Alexandra Paul',
  },
  {
    text: 'Any customer can have a car painted any color that he wants so long as it is black.',
    autor: 'Henry Ford',
  },
  {
    text: 'You can’t build a reputation on what you are going to do.',
    autor: 'Henry Ford',
  },
  {
    text: ' Whether you think you can, or you think you can’t–you’re right.',
    autor: 'Henry Ford',
  },
  {
    text: 'Flying cars are not a very efficient way to move thingsfrom one point to another.',
    autor: 'Bill Gates',
  },
  {
    text: 'A car is like a mother-in-law – if you let it, it will rule your life.',
    autor: 'Jaime Lerner',
  },
  {
    text: 'I couldn’t find the sports car of my dreams, so I built itmyself.',
    autor: 'Ferdinand Porsche',
  },
  {
    text: 'All of those cars were once just a dream in somebody’s head.',
    autor: 'Peter Gabriel',
  },
  {
    text: 'Fast cars like Porsches and Ferraris – they are things of beauty.',
    autor: 'Rod Stewart',
  },
  {
    text: 'There’s no way we could take cars off the planet and not have our society fall apart. So they’re a necessary evil, in that sense.',
    autor: 'Lindsay Wagner',
  },
];

export const randomQuotes = () => {
  const randomNumber: any = Math.random();
  const calc = Math.floor(randomNumber * quotes.length);
  return quotes[calc];
};

export const randomPrice = () =>
  Math.floor(Math.random() * (45000 - 25000 + 1)) + 25000;

export const validateEmail = inputText => {
  if (!inputText) return false;

  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputText)) {
    return true;
  }

  return false;
};
