export const interested_vehicle_auto_block1 = `The 2018 \n Toyota Camry XSE is a great value for its price point. It offers a luxurious interior that features a 7-inch touchscreen, leather-
trimmed seats, and an 8-way power adjustable driver\u2019s seat.
advanced safety features.`;

export const interested_vehicle_auto_block2 = `It also has a powerful 3.5-liter V6 engine that delivers a smooth and
responsive ride. It also comes with a range of advanced driver-assistance technologies, including adaptive cruise control, lane-departure
warning, and automatic high-beam headlights. The Camry XSE also has impressive fuel economy, with an EPA-estimated rating of 32 mpg
combined. All in all, the 2018 Toyota Camry XSE is an excellent value for its price point, offering a luxurious interior, powerful engine, and
advanced safety features.`;

export const interested_vehicle_features_block = `1) Redesigned Styling: The 2023 Toyota Camry will feature a bolder and more dynamic exterior design with a larger grille, new wheel
designs and an available rear spoiler. 2) Updated Interior: The 2023 Camry has a redesigned interior that is more driver-centric and upscale than ever before. It features a larger
touchscreen display, improved materials, and a new digital instrument cluster. 3) Advanced Safety Technologies: The 2023 Camry will include Toyota's latest suite of advanced safety technology, including Pre-Collision
System with Pedestrian Detection and Lane Departure Alert with Steering Assist. 4) Connected Services: Toyota's Entune 3.0 infotainment system is available on the 2023 Camry and provides convenient access to
connected services, such as Apple CarPlay and Amazon Alexa. 5) Hybrid Powertrain: The 2023 Camry is available with a hybrid powertrain that combines a 2.5-liter four-cylinder engine and electric motor
for improved fuel efficiency.`;

export const dealer_intro_auto_block_280 = `MyautoIQ Motors is one of the largest car dealers in the tri-state area. We have a large selection of new Mercedes-Benz models and quality pre-owned inventory. At MyautoIQ Motors, we strive to ensure your satisfaction with our team of competent professionals who always want to exceed your expectations. Come to MyautoIQ Motors today to get the level of service you deserve and enjoy excellence at every level.`;
