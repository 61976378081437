import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        standard: {
          color: '#737373',
          fontSize: '22px',
          fontFamily: ['Sen', 'sans-serif'].join(','),
        },
      },
    },

    // MuiButton: {
    //   styleOverrides: {
    //     text: {
    //       fontSize: '22px',
    //     },
    //   },
    // },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: 'white',
    },
    primary: {
      main: '#ff6600',
      contrastText: 'white',
    },
    secondary: {
      main: '#999999',
      contrastText: 'white',
    },
  },
  typography: {
    h1: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '30px',
      color: '#152952',
      fontWeight: 700,
    },
    h3: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '22px',
      color: '#545454',
    },
    body1: {
      fontFamily: ['Sen', 'sans-serif'].join(','),
    },
  },
});
